import { paginationParamNames } from '../config/api-config'

export const paginatorMixin = {
  data () {
    return {
      mxQueryModel: null,
      mxSearchTimer: null,
      mxSearch: '',
      mxSearchFields: [],
      mxFetcherMethod: null,
      mxDataGetter: null,
      mxPaginationGetter: null,
      mxPageCount: 0,
      mxFilter: {}, 
      mxTotalCount: 0,
      mxLoading: false,
      mxAutoSubmitQuery: true,
      mxSubmitQueryOnInit: true,
      mxExporting: false,
      mxExtraQueryArgsParamBuilder: null,
      mxFullItems: [],
      _queryOptions: {},
      mxPagination: {
        page: 0,
        rowsPerPage: 10,
        sortingParam: ''
      }
    }
  },
  watch: {
    mxSearch: {
      handler () {
        if (this.mxAutoSubmitQuery){
          this.mxReplayQuery()
        }
      }
    },
    mxList: {
      handler () {
        this.mxLoading = false
        this.mxFullItems = [ ...this.$store.getters[this.mxDataGetter] ]
        let pagination = { ...this.$store.getters[this.mxPaginationGetter]}
        this.mxPageCount = pagination.totalPages || 1
        this.mxTotalCount = pagination.totalElements || 1
      },
      deep: true
    }
  },
  computed: {
    mxFilteredItems () {
      this.mxLoading = false
      return this.mxFullItems
    },
    mxList(){
      return this.$store.getters[this.mxDataGetter]
    },
    mxActiveFiltersCount(){
      const _queryOptions = this._queryOptions
      let count = 0
      Object.keys(_queryOptions).forEach(option  => {
        if(option != null && this.mxFilter[option] != null && this.mxFilter[option].value){
          count++;
        }
      })
      return count;
    },
  },
  methods: {
    mxSetOptionValue(optionColumn, value, submitQuery=false){
      if(this.mxFilter[optionColumn] && this.mxFilter[optionColumn].multiple){
        if(this.mxFilter[optionColumn].items && this.mxFilter[optionColumn].items.length != 0){
          const valueKey =  this.mxFilter[optionColumn].valueKey
          this.mxFilter[optionColumn].value = this.mxFilter[optionColumn].items.filter(el => el[valueKey]==value)
        }
        else {
          this.mxFilter[optionColumn].value = [ value ]
        }
      }
      else {
        this.mxFilter[optionColumn].value = value
      }
      if(!this.mxAutoSubmitQuery && submitQuery) this.mxReplayQuery(0)
    },
    mxAddQueryParam(key, value, ulrArg=``){
      if(key && value != null){
        ulrArg += `&${key}=${value}`
      }
      return ulrArg
    },
    mxExportToExcel () {
      return new Promise((resolv, reject) => {
        this.exporting = true
        this.mxLoading = true
        this.mxPagination.rowsPerPage = 1000000
        this.$store.dispatch(this.mxFetcherMethod, {
          queryParams: this.mxGetQueryBuilder()
        }).then(() => {
          this.mxLoading = false
          this.mxExporting = false
          resolv(this.mxList)
        }).catch(err => reject(err))
      })
    },
    mxInitializePaginator ({
      queryModel,
      search,
      fetcherMethod,
      dataGetter,
      paginationGetter,
      searchFields,
      pagination,
      autoSubmitQuery,
      submitQueryOnInit,
      filterOptions,
      extraQueryArgsParamBuilder
    }) {
      // if (!(filterOptions instanceof Array)) {
      //   throw Error(`paginatorMixin.'filterOptions' must be defined as an array.`)
      // }

      this.mxFetcherMethod = fetcherMethod
      this.mxSearchFields = searchFields
      this.mxSearch = search
      this.mxQueryModel = queryModel
      if(autoSubmitQuery != undefined){
        this.mxAutoSubmitQuery = autoSubmitQuery
      }

      this.mxExtraQueryArgsParamBuilder = extraQueryArgsParamBuilder
      
      if (!(filterOptions instanceof Array)) {
        throw Error(`paginatorMixin.'filterOptions' must be defined as an array.`)
      }
      if (dataGetter === undefined) {
        throw Error(`paginatorMixin.'dataGetter' must be defined`)
      }
      if (paginationGetter === undefined) {
        throw Error(`paginatorMixin.'paginationGetter' must be defined`)
      }
      if (this.$store.getters[paginationGetter] === undefined) {
        throw Error(`paginatorMixin.'${paginationGetter}' doesn't exist in your store.`)
      }
      if (this.$store.getters[dataGetter] === undefined) {
        throw Error(`paginatorMixin.'${dataGetter}' doesn't exist in your store.`)
      }
      
      this.mxDataGetter = dataGetter
      this.mxPaginationGetter = paginationGetter
      const queryOptions = {}

    //   if(submitQueryOnInit != null){
    //     this.mxSubmitQueryOnInit = submitQueryOnInit
    //   }

      filterOptions.forEach(item => {
        this.mxFilter[item.column] = item
        queryOptions[item.column] = item
        this.$watch(
          `mxFilter.${item.column}`, () => {
              //execute your code here

              if(this.mxAutoSubmitQuery){
                this.mxReplayQuery()
              }
          },
          { deep: true }
          )
      })

      
      this._queryOptions = queryOptions
      
      console.log(this.mxPagination)
      if (pagination) {
        if(pagination.page) this.mxPagination.page = pagination.page <= 0? 1 : pagination.page
        if(pagination.sortBy) this.mxPagination.rowsPerPage = pagination.sortBy
        if(pagination.rowsPerPage) this.mxPagination.rowsPerPage = pagination.rowsPerPage
        if(pagination.sortingParam) this.mxPagination.sortingParam = pagination.sortingParam
      } // this.mxReplayQuery is called in mxPagination watcher
      
      this.mxReplayQuery(0)
    //   if(this.mxSubmitQueryOnInit){
    //     // alert('I go query')
    //   } else{
    //     // alert('I wont query')
    //   }
      
      this.$watch(
        `mxPagination`, () => {
          if(this.mxExporting) return
          this.mxReplayQuery(0)
        },
        { deep: true }
      )
    },
    mxSubmitQuery(){
      this.mxReplayQuery(0)
    },
    mxReplayQuery (delay = 0) {
      clearTimeout(this.mxSearchTimer)
      this.mxLoading = true
      this.mxSearchTimer = setTimeout(() => {
        this.$store.dispatch(this.mxFetcherMethod, {
          queryParams: this.mxGetQueryBuilder()
        }).then(() => {
          this.mxLoading = false

          console.log(this.mxDataGetter.split('/')[0]+'/pagination')
          const pagination =  this.$store.getters[this.mxDataGetter.split('/')[0]+'/pagination']
          this.mxPageCount = pagination[paginationParamNames.pageCount]
          this.mxTotalCount = pagination[paginationParamNames.totalCount]
        })
      }, delay)
    },
    mxBuildQueryParams(){
      return this.mxGetQueryBuilder()
    },
    mxGetQueryBuilder () {
      let mxQueryBuilder =''
      // let mxQueryBuilder = this.mxQueryModel + '?'
      if (this.mxSearch.length != 0) {
        this.mxSearchFields.forEach((field) => {
          mxQueryBuilder += field + '=' + this.mxSearch + '&'
        })
      }

      const _queryOptions = this._queryOptions
    
      Object.keys(_queryOptions).forEach(option  => {
        // if(this.mxFilter){
          const valueKey = this.mxFilter[option].valueKey || 'id'
          console.log(option, this.mxFilter[option], valueKey)
          let value = this.mxFilter[option] !== undefined && this.mxFilter[option].value instanceof Array ? this.mxFilter[option].value.map(v => v instanceof Object? v[valueKey] : v).join(',') : this.mxFilter[option].value
          if (value) {
            // if(this.mxFilter[option].multiple){
            //   value = this.mxFilter[option].value.map(el => (el instanceof Object? el[valueKey] : el)).join('&'+option+'=')
            // }
            console.log(value)
            
            mxQueryBuilder += option + '=' + (value instanceof Object? value[valueKey] : value) + '&'
          }
        // }
      })


      // this.mxFilters.forEach((option) => {
      //   const value = option.column !== undefined && option.value != undefined && option.value instanceof Array ? this.option.value.join(',') : option.value
      //   if (value) {
      //     mxQueryBuilder += option.column + '=' + value + '&'
      //   }

      // })

      // if (this.mxFilter.ordering) {
      //   mxQueryBuilder += this.mxFilter.ordering
      // }

      const { page, rowsPerPage, sortingParam } = this.mxPagination
      if (page) {
        mxQueryBuilder += paginationParamNames['page'] + '=' + (page <= 0 ? 0 : page-1) + '&'
      }
      if (rowsPerPage) {
        mxQueryBuilder += paginationParamNames['rowsPerPage'] + '=' + rowsPerPage + '&'
      }
      
      if(sortingParam){
        mxQueryBuilder += sortingParam
      }

      if(this.mxExtraQueryArgsParamBuilder instanceof Function) {
        const arg = this.mxExtraQueryArgsParamBuilder()
        mxQueryBuilder += arg != null && arg.startsWith('&')? arg : '&' + arg
      }

      console.log(mxQueryBuilder)


      return mxQueryBuilder
    },

    //Just un helper pour le bootstrap b-table (devrait être dans un mixin séparé) :).
    mxToggleDetails(row) {
        if (row.detailsShowing) {
          row.toggleDetails()
        } else {
          this.tableData.forEach(item => {
            this.$set(item, '_showDetails', false)
          })
          this.$nextTick(() => {
            row.toggleDetails()
          })
        }
      },
      mxToggleDetails(row, data) {
        if (row.detailsShowing) {
          row.toggleDetails()
        } else {
          data.forEach(item => {
            this.$set(item, '_showDetails', false)
          })
          this.$nextTick(() => {
            row.toggleDetails()
          })
        }
    },
    mxExportToExcel({pathParams, meta}){
        let options = this.$store.getters['exporter/exportToExcelSpecs'][pathParams.query.apiPath]
      this.$xdialog.show({
        meta: {
          ...meta,
          options
        },
        onAccept: (editedMeta) => {
          let route = this.$router.resolve({
            ...pathParams,
            query: {
              ...pathParams.query,
              metaTitle: editedMeta.title,
              metaSubtitle: editedMeta.subtitle,
              metaSelectedHeaders: editedMeta.selectedHeaders.join(',')
            }
          });
          // this.$xdialog.hide()
          window.open(route.href, "_blank");
        },
        onCancel: () => {}
      })

    },
    mxExportToPDF({pathParams, meta}){
      this.$xdialog.show({
        meta: {
          ...meta,
        },
        onAccept: (editedMeta) => {
          let route = this.$router.resolve({
            ...pathParams,
            query: {
              ...pathParams.query,
              metaTitle: editedMeta.title,
              metaSubtitle: editedMeta.subtitle
            }
          });
          // this.$xdialog.hide()
          window.open(route.href, "_blank");
        },
        onCancel: () => {}
      })

    }
  }
}
